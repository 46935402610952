$badge-border-width: 0.125rem;
$badge-size: 1.125rem;
.badge {
  font-size: 0.625rem;
  min-width: $badge-size + ($badge-border-width * 2);
  height: $badge-size + ($badge-border-width * 2);
  border-radius: 1.125rem;
  vertical-align: bottom;
  border: $badge-border-width solid $white;
  padding-top: 4px;
  &.badge-darkblue {
    background-color: $blue-dark;
    color: $white;
  }
}
