.#{$prefix}loader-overlay {
  &--loading {
    min-height: 110px;
  }

  &__text {
    margin-top: 1rem;
    color: $grey-47;
  }
}
