.btn {
  &.btn-danger {
    color: $red;
    background-color: $red-100;
    &:hover {
      background-color: $red-200;
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      color: $red;
      background-color: $red-300;
    }
  }
  &.btn-sm {
    & > img:first-child {
      margin-left: -3px;
      margin-right: 2px;
      vertical-align: sub;
    }
  }
}
