.modal-form {
  width: 80%;
  margin: auto;
}

.form-row-centered {
  align-items: center;
  justify-content: center;
}

.history-data-nav {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.bank-account-prefix {
  margin-right: 0.9rem;
}

.self-reading__hints-link {
  font-weight: 600;
  font-size: 14px;
  color: #6c6f77;
  text-decoration: underline;
  cursor: pointer;
}

.self-reading__hints-body {
  font-size: 14px;
  line-height: 19px;
  max-width: 400px;
  margin: -30px auto 40px;
}

.bonusCard {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  font-size: 0.875rem;

  color: var(--blue-dark);

  span {
    color: #6c6f77; // Taken from table td color
  }
}

.slimCardBody {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
