$max-visible-items: 5;

.#{$prefix}supply-point-list {
  .form-control {
    margin-bottom: 0.5rem;
  }

  &__container {
    max-height: $max-visible-items * (75px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 5px;
  }

  .#{$prefix}supply-point-list--item {
    user-select: none;
    transition: background-color 0.15s, color 0.15s, filter 0.15s;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 0.3125rem;
    }

    &:hover {
      .#{$prefix}supply-point-list--item__check {
        opacity: 1;
      }
    }
  }
}
