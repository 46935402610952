.#{$prefix}inputrange {
  display: flex;
  align-items: center;
  .form-control {
    max-width: 100px;
    text-align: center;
    &:first-child {
      margin-right: 1.25rem;
    }
    &:last-child {
      margin-left: 1.25rem;
    }
  }
  .slider {
    // background-color: $gray-200;
    width: 100%;
    .thumb {
      // outline: 1px dashed red;
      // padding: 12px;
      // border: 12px solid transparent;
      // box-sizing: content-box;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background-color: $indigo;
      background-clip: content-box;
      transform: translate(0, -50%);
      cursor: pointer;
      // &.thumb-0 {
      //   transform: translate(-50%, -50%);
      // }
      // &.thumb-1 {
      //   transform: translate(50%, -50%);
      // }
      &:focus {
        outline: none;
      }
      &.active {
        background-color: $blue-800;
      }
    }
    .track {
      height: 1px;
      background-color: $gray-200;
      &.track-1 {
        background-color: $indigo;
        height: 3px;
        margin-top: -1px;
      }
    }
  }
}
