.filter-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  & > section {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }

  .input-search {
    max-width: 30rem;
    margin-left: 1em;
  }
}

.filter-icon {
  width: 18px;
  height: 18px;
}

.advance-change-attachment-text {
  strong,
  span,
  p {
    font-size: 14px;
  }

  .grey-text {
    color: #8a8e98;
  }

  p {
    font-style: italic;
  }
}

.load-more-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hint-text {
  font-style: italic;
  color: #8a8e98;
}

.date-filter {
  font-weight: bold;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;

  &.to {
    margin-top: 1rem;
  }
}

.responsive-table-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}

@media (min-width: 992px) {
  .finance-detail-modal .modal-dialog {
    min-width: 95%;
  }
}

@media (min-width: 1300px) {
  .finance-detail-modal .modal-dialog {
    min-width: 1200px;
  }
}
