// Override default variables before the import
$body-bg: #eee;

// Import Bootstrap and its default variables
@import "~@emanprague/ppaskit/dist/styles/style.scss";

// prevents 'jumping scrollbar'
html {
  margin-right: calc(100% - 100vw);
  overflow-x: hidden;
}
body {
  margin-right: calc(100vw - 100%);
}

.layout-pre-auth .app-loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.toast__container {
  z-index: 1100; // dialog overlay is 1050
  position: absolute;
  top: 5.5rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
}

.supply-points-list-container {
  max-height: 4 * (75px);
  overflow-x: hidden;
  overflow-y: auto;
}
