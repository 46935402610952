$overlay-transparency: 0.5 !default;

.#{$prefix}loader-overlay {
  position: relative;

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--primary {
      background-color: rgba($primary, $overlay-transparency);
    }

    &--secondary {
      background-color: rgba($secondary, $overlay-transparency);
    }

    &--dark {
      background-color: rgba($dark, $overlay-transparency);
    }

    &--light {
      background-color: rgba($light, $overlay-transparency);
    }
  }

  // &__text { }
}
