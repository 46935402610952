$padding-inner: 0.5em;

.filter-button {
  display: inline-block;
  margin-left: 5px;

  .dropdown-toggle {
    padding: 0.25rem;

    &::after {
      content: none;
    }
  }

  svg path {
    fill: currentColor;
  }
}

.filter-button__popup {
  .form-group {
    margin: 0 $padding-inner;
  }
}

.filter-button__popup__footer {
  min-width: 15rem;
  margin: 0 1rem 0.5rem 0.5rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
