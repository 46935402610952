.tooltip-inner {
  max-width: 600px;
}

.executor-box {
  width: 25px;
  display: inline-block;
}

.executor-flexbox {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  column-gap: 5px;
  font-size: small;

  .title {
    align-self: center;
    font-size: medium;
    font-weight: bold;
  }

  .name {
    font-weight: bold;
  }

  .separator {
    width: 100%;
    border-bottom: 1px solid white;
    margin-top: 3px;
    margin-bottom: 5px;
  }
}
