.alert {
  font-weight: normal;
  padding: 14px 14px 14px 59px;
  white-space: pre-line;
  display: flex;
  align-items: center;

  &::before {
    position: absolute;
    left: 15px;
  }
}

.alert .close {
  color: white;
  opacity: 1;
  font-size: 14px;
  text-shadow: none;
  padding: 4px;
  margin-left: 40px;
}

.alert-success {
  background: $green-69;
  color: white;

  &::before {
    content: url(../assets/icons/State/Ok.svg);
  }
}

.alert-danger {
  background: $red-47;
  color: white;

  &::before {
    content: url("../assets/icons/State/Not Ok.svg");
  }
}
