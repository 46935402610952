.products__footer {
  text-align: center;
  color: #6c757d;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0;
}

.products_container {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(260px, auto);
  gap: 30px;
}

@media (max-width: 768px) {
  .products_container {
    grid-template-columns: 1fr;
  }
}

.appendix_row {
  justify-content: space-between;

  .right_segment {
    display: inline-flex;
    align-items: center;
  }
}
