.#{$prefix}sidenav {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;

  .dropdown {
    & > .dropdown-toggle {
      background-color: $white !important;
      padding-top: 0.875rem;
      padding-bottom: 0.875rem;
      padding-left: 0;
      width: 100%;
      text-align: left;
      &::after {
        background-image: url("../assets/icons/Arrow Simple Light/Down.svg");
        height: 1.25rem;
        background-position: center;
        background-size: contain;
        width: 1.3125rem;
        position: absolute;
        margin-left: 0;
        right: 0.875rem;
        margin-top: -0.125rem;
      }
    }
    &.show > .dropdown-toggle {
      &::after {
        background-image: url("../assets/icons/Arrow Simple Light/Up.svg");
      }
    }
    .dropdown-menu {
      width: 100%;
      padding: 0;
      background-color: $body-bg;
      box-shadow: $box-shadow;
      transform: none !important;
      overflow: hidden;
    }
    .active {
      background-color: $white;
      color: $indigo !important;
      font-weight: $font-weight-bold !important;
      letter-spacing: 0 !important;
    }

    .dropdown-divider {
      margin-top: 1.5rem;
      margin-bottom: 1.8rem;
    }

    .dropdown-item {
      border-radius: 0;
      color: $blue-dark;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      position: relative;
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
      letter-spacing: 0.3px;
      /* prettier-ignore */
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, background-image 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:active:not(.active),
      &:hover:not(.active) {
        background-color: transparent;
      }
      .text-float-right {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        font-weight: normal;
        letter-spacing: 0;
      }
    }
    .with-icon {
      padding-left: 3.125rem;
      background-position: left 0.875rem center;
      background-repeat: no-repeat;
      &.icon-home {
        background-image: url("../assets/icons/Home.svg");
        &:hover,
        &.active {
          background-image: url("../assets/icons/Home Fill.svg");
        }
      }
      &.icon-receipt {
        background-image: url("../assets/icons/Receipt.svg");
        &:hover,
        &.active {
          background-image: url("../assets/icons/Receipt Fill.svg");
        }
      }
      &.icon-wrench {
        background-image: url("../assets/icons/Wrench.svg");
        &:hover,
        &.active {
          background-image: url("../assets/icons/Wrench Fill.svg");
        }
      }
      &.icon-user {
        background-image: url("../assets/icons/User.svg");
        &:hover,
        &.active {
          background-image: url("../assets/icons/User Fill.svg");
        }
      }
      &.icon-document {
        background-image: url("../assets/icons/Document.svg");
        &:hover,
        &.active {
          background-image: url("../assets/icons/Document Fill.svg");
        }
      }
      &.icon-graph {
        background-image: url("../assets/icons/Graph.svg");
        &:hover,
        &.active {
          background-image: url("../assets/icons/Graph Fill.svg");
        }
      }
    }

    .without-icon {
      padding-left: 1rem;
    }
  }

  @include media-breakpoint-up(sm) {
    width: 260px;
  }
  @include media-breakpoint-up(lg) {
    .dropdown {
      .dropdown-toggle {
        display: none;
      }
      .dropdown-menu {
        display: block;
        opacity: 1 !important;
        position: relative !important;
        transform: none !important;
        pointer-events: all !important;
        margin-top: 0 !important;
        overflow: visible;
        background-color: transparent !important;
        // max-width: 320px;
        border: 0;
        box-shadow: none;
        .active {
          box-shadow: $box-shadow;
        }
        .dropdown-item {
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
          border-radius: $border-radius;
          margin-bottom: 0.3125rem;
          font-size: $font-size-base;
        }
      }
    }
  }
}
