h1,
h2,
h3,
h4,
h5,
h6 {
  color: $blue-dark;
  &[class^="display-"] {
    font-family: "Klavika", "Impact", "Arial Narrow", "Arial", sans-serif;
  }
}

.text-gray {
  color: $gray-700;
}
.text-indigo {
  color: $indigo;
}

.font-weight-medium {
  font-weight: $font-weight-medium;
}

.font-size-base {
  font-size: $font-size-base;
}
.font-size-xs {
  font-size: $font-size-xs;
}
.font-size-sm {
  font-size: $font-size-sm;
}
.font-size-md {
  font-size: $font-size-md;
}
.font-size-lg {
  font-size: $font-size-lg;
}
// .font-size-xl {
//   font-size: $font-size-sm * 3.25;
// }

@include media-breakpoint-down(xs) {
  .mobile-light {
    font-size: 1rem;
    font-weight: 300;
    color: $gray-600;
  }
}
