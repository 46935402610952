.request-card-body {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  background-color: white;
  padding: 1.5rem;
  width: 100%;
  height: 100%;
}

.request-id {
  font-family: "Klavika", "Impact", "Arial Narrow", "Arial", sans-serif;
  font-size: 24px;
  line-height: 29px;
}

.request-badge {
  padding: 5px 10px;
  font-size: 14px;

  &.active {
    background-color: #D1FFD8;
    color: #00C34C;
  }

  &.done {
    background-color: #F3F3F4;
    color: #8A8E98;
  }

  &.rejected {
    background-color: #ffd1d1;
    color: #c30000;
  }
}

.request-title {
  word-break: break-all;
}

@include media-breakpoint-up(md) {
  .request-title {
    max-width: 65%;
  }
}
