.tooltip-inner {
  text-align: left;
  max-width: 340px;
  font-size: $font-size-sm;
  @include media-breakpoint-down(xs) {
    max-width: 320px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: -5px;
  }
}
