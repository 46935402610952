.#{$prefix}dropdown {
  &--block > &__btn + .dropdown-menu.show {
    right: 0px !important;
  }
  &--block + &--block {
    margin-top: $btn-block-spacing-y;
  }
  &:not(&--block) {
    display: inline-flex;
  }
  &:not(&--block) + &:not(&--block) {
    margin-left: ($btn-padding-x / 2);
  }
}
