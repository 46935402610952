.#{$prefix}supplypointnav {
  display: flex;
  flex-direction: row;
  // flex-wrap: nowrap;
  flex-basis: auto;
  align-items: center;
  justify-content: space-between;
  @include media-breakpoint-up(md) {
    justify-content: flex-start;
  }

  &__rootTitle {
    text-decoration: underline;
    &__label {
      display: none;
      @include media-breakpoint-up(md) {
        display: inline;
      }
    }
  }
  &__rootTitle,
  &__currentTitle {
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
    white-space: nowrap;
  }
  &__currentTitle {
    display: none;
    @include media-breakpoint-up(md) {
      display: inline;
    }
  }

  &__arrow {
    margin-left: 0.625em;
    margin-right: 0.625em;
    transform: none;
    @include media-breakpoint-down(sm) {
      transform: scaleX(-1);
    }
  }

  &__fakeselect {
    position: relative;
    border: 1px solid $input-border-color;
    border-radius: $border-radius;
    padding-left: $input-btn-padding-x-lg;
    padding-right: $input-btn-padding-x-lg;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;

    display: flex;
    align-items: center;
    &__icon {
      margin-right: 0.625rem;
    }
    &__title {
      font-size: $font-size-md;
      font-weight: $font-weight-bold;
      color: $blue-dark;
      margin-right: 1rem;
    }
    &__label {
      color: $indigo;
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;
      cursor: default;
      display: none;
      @include media-breakpoint-up(md) {
        display: inline;
      }
    }
    &__arrow {
      margin-left: 0.5rem;
    }
    .form-control {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
}
