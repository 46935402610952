$stepperrequestMinWidthMd: 150px;
$stepperrequestMinWidthLg: 180px;
$lineAddedWidth: 30px;

.#{$prefix}stepperrequest {
  .step {
    display: flex;
    align-items: center;
    font-size: $font-size-sm;
    margin-bottom: 2.5rem;
    width: 100%;

    .step-circle {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      max-width: 2rem;
      max-height: 2rem;
      background-color: $white;
      border: 1px solid $gray-200;
      border-radius: 50%;
      margin-right: 0.625rem;
      font-size: $font-size-base;
      &::after {
        position: absolute;
        display: block;
        content: "";
        border-top: 0;
        border-bottom: 0;
        border-right: 0;
        border-left: 1px dashed $gray-200;
        height: calc(2.5rem + 2px);
        top: 100%;
      }

      &.last-passed-step {
        &::after {
          width: ($stepperrequestMinWidthMd + $lineAddedWidth) / 2;
        }
      }
    }

    .first-incomplete-step {
      border-bottom: 1px dashed $gray-200;
      position: absolute;
      width: 50%;
      left: 0;
      margin-bottom: 8px;
    }

      .step-note {
      position: absolute;
      top: 2.6rem;
      left: 2.6rem;
      font-size: $font-size-xs;
    }
    &.step-passed {
      .step-name {
        color: $green-500;
        font-weight: $font-weight-medium;
      }
      .step-circle {
        border-color: $green-500;
        background-color: $green-500;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 8L6 12L14 4' stroke='%23FFF' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        position: relative;
        .step-value {
          display: none;
        }
        &::after {
          border-color: $green-500;
          border-style: solid;
          border-left-width: 2px;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
      .step-circle {
        &::after {
          display: none;
        }
      }
    }

    @include media-breakpoint-up(md) {
      & {
        margin-bottom: 0;
        width: auto;
        flex-direction: column;
        margin-left: 1rem;
        margin-right: 1rem;
        min-width: $stepperrequestMinWidthMd;
        max-width: $stepperrequestMinWidthMd;
        .step-circle {
          position: relative;
          margin-bottom: 0.5rem;
          margin-right: 0;
          &::after {
            border-top: 1px dashed $gray-200;
            border-bottom: 0;
            border-right: 0;
            border-left: 0;
            width: ($stepperrequestMinWidthMd + $lineAddedWidth);
            height: 0;
            top: auto;
            left: 100%;
          }

          &.last-passed-step {
            ::after {
              width: ($stepperrequestMinWidthMd + $lineAddedWidth) / 2;
            }
          }
        }
        &.step-passed {
          .step-circle {
            &::after {
              border-top-width: 2px;
            }
          }
        }
        .step-name {
          text-align: center;
        }
        .step-note {
          display: block;
          width: $stepperrequestMinWidthMd;
          top: -1rem;
          left: 111%;
          text-align: center;
          transform: translate(-50%, 0);
        }
      }
    }
    @include media-breakpoint-up(lg) {
      & {
        min-width: $stepperrequestMinWidthLg;
        max-width: $stepperrequestMinWidthLg;
        .step-note {
          left: 109%;
          width: $stepperrequestMinWidthLg;
        }
        .step-circle {
          &::after {
            width: $stepperrequestMinWidthLg + $lineAddedWidth;
          }

          &.last-passed-step {
            &::after {
              width: ($stepperrequestMinWidthLg + $lineAddedWidth) / 2;
            }
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {
      &.step-passed {
        .step-circle {
          &.last-passed-step {
            &::after {
              border-top: 0;
              border-bottom: 0;
              border-right: 0;
              border-left: 2px solid $green-500;
              height: 25px;
              width: 5px;
            }
          }
        }
      }

      .first-incomplete-step {
        border-bottom: none;
        border-right: 1px dashed $gray-200;
        height: 50%;
        top: -50%;
        width: 0;
        margin-left: 14px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    & {
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
