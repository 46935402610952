.form-label {
  font-size: 0.875rem;
  line-height: 1.35;
  color: $blue-dark;
}

.valid-feedback,
.invalid-feedback {
  font-weight: $font-weight-medium;
  display: block;
}

.invalid-label {
  color: $danger;
}

.was-validated .form-control:invalid:hover,
.form-control.is-invalid:hover {
  border-color: $form-feedback-invalid-color;
}
.was-validated .form-control:valid:focus,
.was-validated .custom-select:valid:focus,
.form-control.is-valid:focus {
  border-color: $input-focus-border-color;
}

input:hover:not(:focus):not(.is-invalid),
textarea:hover:not(:focus):not(.is-invalid),
.custom-select:hover:not(:focus):not(.is-invalid) {
  border-color: $gray-300;
}

input::placeholder,
textarea::placeholder {
  font-weight: $font-weight-base;
  transition: $btn-transition;
}
input:hover::placeholder,
input:focus::placeholder,
textarea:hover::placeholder,
textarea:focus::placeholder {
  color: $gray-500;
}

textarea.form-control:placeholder-shown {
  font-weight: $font-weight-normal;
}

$checkboxHeight: 1.25rem;
$checkboxHeightWithPadding: $checkboxHeight + 0.5rem; /*$checkboxHeight + ($checkboxHeight / 4);*/

$radioHeight: 1.25rem;
$radioHeightWithPadding: $radioHeight + 0.5rem; /*$radioHeight + ($radioHeight / 4);*/

.custom-control {
  &.custom-control-inline {
    margin-right: (48 / 16) * 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
  & > .custom-control-input {
    cursor: pointer;
    opacity: 0;
    z-index: 0;

    &:active ~ .custom-control-label::before,
    &:disabled ~ .custom-control-label::before {
      background-color: transparent !important;
    }
    &:disabled ~ .custom-control-label::before {
      opacity: 0.5;
    }

    & ~ .custom-control-label {
      user-select: none;
      font-size: $input-font-size;
      line-height: normal;
      font-weight: $font-weight-medium;
      color: $blue-dark;
    }

    & ~ .custom-control-label::before {
      top: 0;
      transition: background 0.15s ease;
      border: none;
      pointer-events: none;
      background-repeat: no-repeat;
      background-color: transparent;
      border-radius: 0.125rem;
    }
    & ~ .custom-control-label::after {
      display: none;
    }
    &:checked {
      & ~ .custom-control-label::before {
        background-color: transparent !important;
      }
    }
  }

  &.custom-radio {
    min-height: $radioHeight;
    padding-left: $radioHeightWithPadding;

    & > .custom-control-input {
      min-width: $radioHeightWithPadding;
      min-height: $radioHeight;
      padding-left: $radioHeightWithPadding;

      & ~ .custom-control-label::before {
        width: $radioHeight;
        height: $radioHeight;
        left: -$radioHeightWithPadding;
      }

      & {
        & ~ .custom-control-label::before {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='19' height='19' rx='9.5' stroke='%23C5C6CB'/%3E%3C/svg%3E%0A");
        }
        &:hover ~ .custom-control-label::before {
          // background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='19' height='19' rx='9.5' stroke='%23C5C6CB'/%3E%3Ccircle cx='10' cy='10' r='4' fill='%238A8E98'/%3E%3C/svg%3E%0A");
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='19' height='19' rx='9.5' stroke='%23C5C6CB'/%3E%3Ccircle cx='10' cy='10' r='4' fill='%23004582'/%3E%3C/svg%3E%0A");
        }
      }
      &:checked {
        & ~ .custom-control-label::before {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='19' height='19' rx='9.5' stroke='%23004582'/%3E%3Ccircle cx='10' cy='10' r='5' fill='%23004582'/%3E%3C/svg%3E%0A");
        }
        &:hover ~ .custom-control-label::before {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='19' height='19' rx='9.5' stroke='%230070CB'/%3E%3Ccircle cx='10' cy='10' r='4' fill='%230070CB'/%3E%3C/svg%3E%0A");
        }
      }
    }
  }

  &.custom-checkbox {
    min-height: $checkboxHeight;
    padding-left: $checkboxHeightWithPadding;

    & > .custom-control-input {
      min-width: $checkboxHeightWithPadding;
      min-height: $checkboxHeight;
      padding-left: $checkboxHeightWithPadding;

      & ~ .custom-control-label::before {
        width: $checkboxHeight;
        height: $checkboxHeight;
        left: -$checkboxHeightWithPadding;
      }
      & {
        & ~ .custom-control-label::before {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='19' height='19' rx='1.5' stroke='%23C5C6CB'/%3E%3C/svg%3E%0A");
        }
        &:hover ~ .custom-control-label::before {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='19' height='19' rx='1.5' stroke='%23C5C6CB'/%3E%3Cpath d='M4 10L8 14L16 6' stroke='%23004582' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }
      }
      &:indeterminate {
        & ~ .custom-control-label::before {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='2' fill='%23004582'/%3E%3Cline x1='4' y1='10.5' x2='16' y2='10.5' stroke='white' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }
        &:hover ~ .custom-control-label::before {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='2' fill='%230070CB'/%3E%3Cline x1='4' y1='10.5' x2='16' y2='10.5' stroke='white' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }
      }
      &:checked {
        & ~ .custom-control-label::before {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='2' fill='%23004582'/%3E%3Cpath d='M4 10L8 14L16 6' stroke='white' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }
        &:hover ~ .custom-control-label::before {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='2' fill='%230070CB'/%3E%3Cpath d='M4 10L8 14L16 6' stroke='white' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }
      }
    }
  }
}
.custom-select {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%2300264C' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
  background-size: 12px 6px;
  background-position: center right 0.75rem;
  padding-right: 2rem;
  text-overflow: ellipsis;
}

// Unable to set empty/placeholder for unselected select
// .custom-select:empty,
// .custom-select[value],
// .custom-select[value=""] {
//   font-weight: normal !important;
// }
// Nothing of above works, must be implemented in React like http://derpturkey.com/select-placeholder-with-react/
