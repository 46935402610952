.product-card__body-text {
  color: #00264c;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0;
  width: 100%;
  white-space: pre-line;

  @include media-breakpoint-up(md) {
    width: 80%;
  }
}
