.#{$prefix}notification-bar {
  &.notification-bar-gradient {
    background-image: linear-gradient(90deg, $ppas-blue-gradient-start, $ppas-blue-gradient-stop);
    box-shadow: 0px 10px 20px rgba(0, 83, 153, 0.2);
  }

  overflow: hidden;
  position: relative;
  color: $white;

  .notification-container {
    position: relative;
    & > * {
      @extend .py-3;
      @extend .px-4;
      @extend .py-md-2;
    }
  }
  .notification-close {
    position: absolute;
    top: 0.25rem;
    right: 1rem;
    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    transition: transform 0.5s ease;
    &:hover {
      transform: rotate(90deg);
    }
    &:focus {
      outline: 0;
    }
  }
}
