.#{$prefix}search {
  .form-control.with-icon {
    padding-left: 36/16 * 1rem;
    padding-right: 8/16 * 1rem;
    background-repeat: no-repeat;
    background-size: 14px 14px;
    background-position: left 12px center;

    &.transparent-background {
      background-color: transparent;
    }

    &.icon-search {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='6' cy='6' r='5' stroke='%23C5C6CB'/%3E%3Cpath d='M9.5 9.5L15 15' stroke='%23C5C6CB'/%3E%3C/svg%3E%0A");
    }

    + .input-inner-element {
      opacity: 0;
      transition: opacity 0.3s;
      &.visible {
        opacity: 1;
      }
      & > * {
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.05029 10.9497L10.9498 1.05024' stroke='%2300264C'/%3E%3Cpath d='M1.05029 1.05026L10.9498 10.9498' stroke='%2300264C'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        height: 16px;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
