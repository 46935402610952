@import "_fonts";

@import "~bootstrap/scss/_functions";
@import "_bootstrapVariables"; // prepare custom bootstrap variables

@import "~@emanprague/basekit/dist/styles/_kitVariables.scss"; // Load basekit variables first...
@import "_kitVariables"; // ...then, optionally, overwrite them with custom variables

@import "_bootstrap"; // Build custom bootstrap

@import "~@emanprague/basekit/dist/styles/_components"; // Import basekit components SCSS
@import "components"; // Import uikit components custom SCSS (eventually overwrite those from basekit)

/* *********** Common helper classes ************* */

.z-index-1 {
  z-index: 1;
}

.position-y-top {
  top: 0;
}
.position-x-center {
  left: 50%;
}
.translate-50 {
  transform: translate(-50%, -50%);
}

.disable-pointer {
  pointer-events: none;
}

.cursor-help {
  cursor: help !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.version {
  text-align: right;
  color: $gray-600;
  padding-right: 10px;
  font-size: 14px;
}
