
.subsidy-details-table {
  th,
  td {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: small;
  }

  tr:last-child {
    th, td {
      font-weight: bold;
      font-size: medium;
    }
    border-top: 1px solid white;
  }

  th {
    font-weight: normal;
  }

  td {
    white-space: nowrap;
    text-align: right;
    padding-left: 10px;
    font-weight: bold;
  }
}

.subsidy-info-box {
  width: 25px;
  display: inline-block;
}
