.#{$prefix}supply-point-list--item {
  font-size: $font-size-sm;
  color: $blue-dark;
  background-color: $gray-100;
  border-radius: $border-radius-sm;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.blue {
    background-color: $blue-100;
  }

  &__icon {
    min-width: 34px;
    height: 34px;
    margin-right: 0.75rem;
  }

  &__inner {
    margin-right: auto;
    overflow: hidden;
    line-height: $line-height-sm;
    & > div {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__title {
    font-weight: $font-weight-bold;
  }

  &__check {
    margin-left: 1rem;
    opacity: 0;
    filter: brightness(3) sepia(1) hue-rotate(175deg) saturate(5);
  }

  &.selected {
    background-color: $indigo;
    color: $white;

    .#{$prefix}supply-point-list--item__icon {
      filter: grayscale(1) brightness(2);
    }

    .#{$prefix}supply-point-list--item__check {
      opacity: 1;
      filter: grayscale(1) invert(1);
    }
  }

  &__error {
    margin-bottom: 0.7rem;
    margin-top: 0.3125rem;
    font-size: 0.875rem;
    color: $red-47;
    font-weight: 600;
    width: 100%;
    display: flex;
  }
}
