.file-row {
  background-color: #f3f3f4;
  color: #00264c;
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  width: 100%;

  .file-name-wrapper {
    display: flex;
    align-items: center;
    width: 80%;
  }

  .file-name {
    overflow-wrap: break-word;
    padding: 10px 0;
    width: 90%;
  }
}
