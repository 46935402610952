.link-underline {
  text-decoration: underline !important;
  &:hover {
    text-decoration: none !important;
  }
}

.link-underline-hover {
  text-decoration: none !important;
  &:hover {
    text-decoration: underline !important;
  }
}

.link-underline-always {
  text-decoration: underline !important;
  &:hover {
    text-decoration: underline !important;
  }
}

.link-underline-never {
  text-decoration: none !important;
  &:hover {
    text-decoration: none !important;
  }
}

.border-dashed {
  border-style: dashed !important;
}
