.button__tooltip {
  position: relative;
  display: inline-block;
}

.button__tooltip .tooltip-text {
  visibility: hidden;
  background-color: $blue-1100;
  color: $white;
  text-align: center;
  border-radius: 6px;
  padding: 7px 10px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -31px;
  margin-bottom: 5px;
  min-width: 60px;
  font-size: 12px;
}

.button__tooltip .last_tooltip {
  left: initial;
  right: 0;
}

.button__tooltip:hover .tooltip-text {
  visibility: visible;
}
