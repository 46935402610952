@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=auto");

@font-face {
  font-family: "Klavika";
  src: local("Klavika-Light"), url("../assets/fonts/Klavika-Light.woff2") format("woff2"),
    url("../assets/fonts/Klavika-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Klavika";
  src: local("Klavika-Regular"), url("../assets/fonts/Klavika-Regular.woff2") format("woff2"),
    url("../assets/fonts/Klavika-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Klavika";
  src: local("Klavika-Medium"), url("../assets/fonts/Klavika-Medium.woff2") format("woff2"),
    url("../assets/fonts/Klavika-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

// @font-face {
//   font-family: "Klavika";
//   src: local("Klavika-Bold"), url("../assets/fonts/Klavika-Bold.woff2") format("woff2"),
//     url("../assets/fonts/Klavika-Bold.woff") format("woff");
//   font-weight: bold;
//   font-style: normal;
// }
