.#{$prefix}payment-method-list--item {
  font-size: $font-size-sm;
  color: $blue-dark;
  background-color: $blue-96;
  border-radius: $border-radius-sm;
  padding: 0.7rem 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 50px;
  min-width: 400px;

  &:hover {
    color: $blue-47;
  }

  &__icon {
    min-width: 34px;
    height: 34px;
    margin-right: 0.75rem;
  }

  &__inner {
    line-height: $line-height-sm;
    width: 60%;

    & > div {
      text-overflow: ellipsis;
      word-wrap: break-word;
    }
  }

  &__title {
    font-weight: $font-weight-bold;
  }

  &__check {
    background: $blue-96;
    border-radius: 3px;
    margin-right: 1.2rem;
    height: 22px;
  }

  &.selected {
    background-color: $indigo;
    color: $white;
  }

  &__image {
    width: 40%;
    min-width: 150px;
  }
}
