.gray-text {
  color: #8a8e98;
}

.request_table {
  margin: 0 auto;
  max-width: 90%;
  th {
    text-align: right;
    padding-right: 1rem;
  }
  td {
    text-align: left;
    padding: 0.5rem 0;
  }
  tr:not(:first-child) {
    color: grey;
  }
}
