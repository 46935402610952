.dropdown {
  .dropdown-toggle {
    &::after {
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%238A8E98' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      width: 1em;
      border: none;
      height: 0.5em;
      background-repeat: no-repeat;
      margin-left: 0.5em;
      background-position: bottom;
    }
  }
  &.show {
    .dropdown-toggle {
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6' fill='none'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%238A8E98' stroke-linejoin='round' style='&%2310; transform: scale(1, -1);&%2310; transform-origin: center;&%2310;'/%3E%3C/svg%3E");
      }
    }
  }

  &.without-caret {
    .dropdown-toggle {
      &::before,
      &::after {
        display: none;
      }
    }
  }
}
