.#{$prefix}input-phone {
  margin-left: 0;
  margin-right: 0;

  .#{$prefix}input-phone__container {
    width: 100%;
    background-color: $input-bg;
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
    transition: $input-transition;

    display: flex;
    overflow: hidden;

    &.focus {
      border-color: $input-focus-border-color;
    }
    &.is-invalid {
      border-color: $form-feedback-invalid-color;
    }
    &.is-valid {
      border-color: $form-feedback-valid-color;
    }
  }

  .form-group {
    padding: 0;
    margin-bottom: 0;
  }

  .form-control,
  .custom-select {
    border: 0;
  }
  .form-control {
    padding-left: 0;
  }
  .custom-select {
    font-weight: $font-weight-medium;
    width: auto;

    &:focus {
      color: $blue-47;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%230070CB' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
}

    option {
      color: $input-color;
    }
  }
}
