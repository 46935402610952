.#{$prefix}linklist {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    a {
      display: inline-block;
      font-size: $font-size-md;
      color: $indigo;
      @extend .pt-2;
      @extend .pb-2;
      @extend .pb-md-1;
      &:first-of-type {
        padding-top: 0 !important;
      }
      &:last-of-type {
        padding-bottom: 0 !important;
      }
    }
  }
}
