.#{$prefix}switchnav.btn-group.btn-group-toggle {
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $font-weight-medium;
    line-height: $line-height-lg;
    padding: 0;
    a {
      text-decoration: none;
      color: inherit;
      padding: $btn-padding-y $btn-padding-x;
    }
    &.btn-sm {
      a {
        padding: $btn-padding-y-sm $btn-padding-x-sm;
      }
    }
    &.btn-lg {
      a {
        padding: $btn-padding-y-lg $btn-padding-x-lg;
      }
    }
    span {
      display: inline-block;
      font-weight: $font-weight-normal;
      font-size: $font-size-sm;
      &::first-line {
        font-weight: $font-weight-bold;
        font-size: $font-size-base;
      }
    }
    &:not(:last-child) {
      margin-right: 1px;
    }
  }
  & > .btn:not(.active) {
    color: $gray-700;
    background-color: $gray-100;
  }
  & > .btn.btn-light:not(.active) {
    background-color: $white;
  }
  .active {
    background-color: $indigo;
    color: $white;
  }
}
