.#{$prefix}stepper {
  @extend .mb-4;
  @extend .mb-md-5;

  .step {
    display: none;
    align-items: center;
    font-size: $font-size-lg;

    &.step-active {
      display: flex;
      margin-left: auto;
      margin-right: auto;
      .step-name {
        font-weight: bold;
      }
    }

    @include media-breakpoint-up(md) {
      & {
        display: flex;

        &:not(:first-child) {
          padding-left: 45px;
        }
        &:not(:last-child) {
          padding-right: 45px;
        }

        &:not(:last-child)::after,
        &:not(:first-child)::before {
          content: "";
          display: block;
          width: 25px;
          position: absolute;
          border-bottom: 1px solid $gray-200;
        }
        &:not(:last-child)::after {
          right: 0;
        }
        &:not(:first-child)::before {
          left: 0;
        }

        .step-total {
          display: none;
        }
        .step-circle {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 2rem;
          height: 2rem;
          max-width: 2rem;
          max-height: 2rem;
          border: 1px solid $gray-200;
          border-radius: 50%;
          margin-right: 0.625rem;
          font-size: $font-size-base;
        }

        &.step-active {
          margin-left: 0;
          margin-right: 0;

          .step-circle {
            content: "";
            background-color: $blue-dark;
            border-color: $blue-dark;
            color: $white;
          }
        }
        &.step-passed {
          .step-name {
            color: $gray-600;
          }
          .step-circle {
            border-color: $green-500;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 8L6 12L14 4' stroke='%2300C34C' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            .step-value {
              display: none;
            }
          }
        }
      }
    }
  }
}
