.table {
  &:last-child {
    margin-bottom: 0;
  }
  @include media-breakpoint-up(sm) {
    &:last-child {
      margin-bottom: -0.5rem;
    }
  }
  tr {
    margin-bottom: 1px;
  }
  &.no-wrap thead tr th {
    vertical-align: baseline;
    white-space: nowrap;
  }
  &.wider-row tbody tr {
    height: 50px;
    td,
    th {
      vertical-align: middle;
    }
  }
  &.responsive-col2row thead tr:first-child,
  &:not(.responsive-col2row) > thead:first-child > tr:first-child,
  &:not(.responsive-col2row) > tbody:first-child > tr:first-child {
    th,
    td {
      border-top: none;
      border-bottom: none;
    }
  }
  th,
  td {
    font-size: $font-size-sm;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    border-color: $table-border-color;

    vertical-align: middle;
    color: $gray-700;
  }
  th:first-child,
  td:first-child {
    padding-left: 0;
  }
  th:last-child,
  td:last-child {
    padding-right: 0;
    // color: $gray-700;
  }
  &.last-column-right {
    tr {
      th:last-child,
      td:last-child {
        text-align: right;
      }
    }
  }
  thead + tbody {
    tr:first-child {
      td {
        border-top: $table-border-width solid $table-border-color;
      }
    }
  }
  &.aligned {
    td,
    th {
      vertical-align: middle;
    }
    tr > td:first-child,
    tr > th:first-child {
      width: 40%;
    }
    tr > td:first-child,
    tr > th:first-child {
      width: 40%;
    }
  }

  .cell-right {
    text-align: right;
  }

  th {
    color: $blue-dark;
  }
}

@include media-breakpoint-down(md) {
  .table.responsive-col2row thead {
    display: table-header-group;
    vertical-align: middle;
  }

  .table.responsive-col2row tbody th {
    display: table-cell;
    text-align: unset;
  }

  .table.responsive-col2row tr td {
    display: table-cell;
    text-align: unset;
  }

  .table.responsive-col2row tr td:before {
    display: none;
  }

  .table.responsive-col2row thead {
    display: none;
  }

  .table.responsive-col2row tbody th {
    display: none;
  }

  .table.responsive-col2row tr td {
    display: block;
    clear: both;
  }

  .table.responsive-col2row tr td:before,
  .table.responsive-col2row tr td:after {
    content: "";
    display: table;
  }

  .table.responsive-col2row tr td:after {
    clear: both;
  }

  .table.responsive-col2row tr td[data-title]:before {
    color: $blue-dark;
    content: attr(data-title);
    font-weight: $font-weight-bold;
    float: left;
    width: 40%;
  }

  .table.responsive-col2row tr td.product-remove a {
    text-align: left;
  }

  .table.responsive-col2row tr td.actions:before,
  .table.responsive-col2row tr td.download-actions:before {
    display: none;
  }

  .table.responsive-col2row tr td.download-actions .button {
    display: block;
    text-align: center;
  }

  .table.responsive-col2row.my_account_orders .order-actions {
    text-align: right;
  }

  .table.responsive-col2row.my_account_orders .order-actions:before {
    display: none;
  }

  .table.responsive-col2row {
    th,
    td {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .table.responsive-col2row tr:first-child td:before {
    position: relative;
  }
  .table.responsive-col2row th {
    padding-bottom: 1rem;
  }

  .table.responsive-col2row tr td,
  .card .card-body .table.responsive-col2row {
    border-top: 0 !important;
  }
  .table.responsive-col2row tr:not(:last-child) td:last-child,
  .card .card-body .table.responsive-col2row tr:not(:last-child) td:last-child {
    border-bottom: $table-border-width solid $table-border-color !important;
  }
}

// generic responsive

.table-list-view {
  line-height: 120%;

  td,
  th {
    border: none;
  }

  tbody + tbody {
    border-top: 1px solid $grey-89;
  }

  .btn-responsive-block {
    @extend .btn-block;
  }

  .cell-right {
    text-align: unset;
  }
}
