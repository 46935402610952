.#{$prefix}uploader {
  border: 1px dashed $gray-300;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: $font-size-sm;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;

  div:focus {
    outline: none;
  }
}

.#{$prefix}selected-files {
  .file-row {
    background-color: $gray-100;
    color: $blue-15;
    font-size: 14px;
    font-weight: 600;
    border: none;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    width: 100%;

    &:first-child {
      margin-top: 1.2rem;
    }

    .file-name-wrapper {
      display: flex;
      align-items: center;
      width: 80%;
    }

    .file-name {
      overflow-wrap: break-word;
      padding: 10px 0;
      width: 90%;
    }

    .file-remove {
      cursor: pointer;
    }
  }

  .file-row:not(:last-child) {
    margin-bottom: 0.25rem;
  }
}
