.#{$prefix}date-input {
  display: flex;
}

.#{$prefix}date-input__day,
.#{$prefix}date-input__month {
  flex-basis: 5em;
  flex-grow: 1;
  margin-right: 0.5rem;
  min-width: 0;
}
.#{$prefix}date-input__year {
  flex-basis: 7em;
  flex-grow: 2;
  min-width: 0;
}
