.#{$prefix}downloading_queue {
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  position: fixed;
  right: 10px;
  bottom: 84px;
  z-index: 200;
  width: 400px;
  padding: 15px 20px;
  background: $white;

  h1 {
    margin: 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  section {
    margin-top: 20px;
    max-height: 180px;
    overflow-x: hidden;
    overflow-y: auto;

    .item {
      display: flex;
      user-select: none;
      border-bottom: 1px solid $grey-89;
      padding-top: 13px;
      padding-bottom: 12px;
      cursor: pointer;

      .kit__loader {
        width: 19px;
        height: 19px;
      }

      &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &:last-child() {
        padding-bottom: 0px;
        border-bottom: none;
      }

      .item_content {
        flex-grow: 1;
        padding-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
