.modal-content {
  button.close {
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.48511 20.4852L20.4557 3.51467' stroke='%2300264C'/%3E%3Cpath d='M3.48511 3.51477L20.4557 20.4853' stroke='%2300264C'/%3E%3C/svg%3E%0A");
    background-position: center;
    &:focus {
      outline: none;
    }
    span:not(.sr-only) {
      opacity: 0;
    }
  }
}
