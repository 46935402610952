$tabsnav-padding: 0.5rem;
/* prettier-ignore */
$tabsnav-gradient-left: linear-gradient( to right, rgba(243, 243, 244, 1) 0%, rgba(243, 243, 244, 1) 5%, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0) 100% );
/* prettier-ignore */
$tabsnav-gradient-right: linear-gradient( to left, rgba(243, 243, 244, 1) 0%, rgba(243, 243, 244, 1) 5%, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0) 100% );

.#{$prefix}tabsnav {
  position: relative;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: $tabsnav-padding * 3;
    height: 100%;
    top: 0;
  }
  &::before {
    left: 0;
    background-image: $tabsnav-gradient-left;
  }
  &::after {
    right: 0;
    background-image: $tabsnav-gradient-right;
  }
  @include media-breakpoint-up(md) {
    &::before,
    &::after {
      display: none;
    }
  }

  .nav.nav-tabs {
    overflow-y: hidden;
    overflow-x: auto;
    border-bottom: 1px solid $nav-divider-color;
    padding-left: $tabsnav-padding;
    padding-right: $tabsnav-padding;
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    flex-wrap: nowrap;
    justify-content: start;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up(md) {
      font-size: $font-size-md;
      justify-content: center;
    }
    & > .nav-item {
      padding: 0 1rem;
      & > .nav-link {
        white-space: nowrap;
        padding: 1rem 0;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 3px solid transparent;
        color: $blue-dark;
        &.active,
        &:hover {
          border-top: none;
          border-left: none;
          border-right: none;
          color: $indigo;
        }
        &:not(.active) {
          letter-spacing: 0.5px;
        }
        &.active {
          font-weight: $font-weight-bold;
          border-bottom: 3px solid $indigo;
        }
      }
    }
  }
}
