.notification__more-button {
  background-color: #53a5e7;
  color: white;

  &:hover, &:active, &:focus {
    background-color: #80b4e5;
    color: white;
  }
}

.partner-connect__card {
  width: 500px;
}
