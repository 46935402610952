.card {
  box-shadow: $box-shadow;
  & > .card-body {
    padding: 0.975rem;
    @include media-breakpoint-up(sm) {
      padding: 1.875rem;
    }
  }

  @include media-breakpoint-down(xs) {
    border-radius: 0;
  }
}

.#{$prefix}layout.layout-pre-auth {
  .card-group {
    @extend .mx-auto;
    @extend .mb-5;

    &.card-single {
      max-width: 500px;
    }

    @include media-breakpoint-up(sm) {
      .border-right-sm {
        border-right: $table-border-width solid $table-border-color !important;
      }
    }

    & > .card {
      & > .card-body {
        @extend .px-3;
        @extend .py-4;
        @extend .px-md-5;
        @extend .py-md-5;
      }
    }
  }
}
