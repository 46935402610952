@import "~react-datepicker/src/stylesheets/datepicker";

$navigation-button-width: 24px;
$navigation-button-offset: 6px;
$padding: 30px;
$spacer-lg: 20px;
$spacer-sm: 5px;
$day-width: 40px;

.react-datepicker-popper {
  z-index: 5;
}

div.react-datepicker {
  font-family: $font-family-base;
  font-size: 1rem;
  font-weight: 600;
  padding: $padding;
  border: $card-border-width solid $card-border-color;
  box-shadow: $box-shadow;
  @include border-radius($card-border-radius);

  .react-datepicker__month-container + .react-datepicker__month-container {
    padding-left: 50px;
  }

  .react-datepicker__navigation {
    border: none;
    width: $navigation-button-width;
    height: $navigation-button-width;
    top: $padding + $navigation-button-offset;

    &::after {
      content: url(../assets/icons/Arrow/Right.svg);
      display: block;
      position: absolute;
      text-indent: 0;
      top: 0;
    }
  }

  .react-datepicker__navigation--previous {
    left: $padding;

    &::after {
      left: 0;
      transform: scaleX(-1);
    }
  }

  .react-datepicker__navigation--next {
    right: $padding;

    &::after {
      right: 0;
    }
  }

  .react-datepicker__header {
    padding: 0;
    background: none;
    border: none;
  }

  .react-datepicker__current-month--hasYearDropdown,
  .react-datepicker__current-month--hasMonthDropdown {
    display: none;
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    font-size: 14px;
    font-weight: 500;
    border: none;
    background: $grey-96;
    padding: 6px 12px;
    @include border-radius($input-border-radius-sm);
  }

  .react-datepicker__day-names {
    padding-top: $spacer-lg;

    .react-datepicker__day-name {
      color: $grey-47;
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    background: white;
    color: $blue-15;
    width: $day-width;
    height: $day-width;
    line-height: $day-width - 4px;
    border: 2px solid transparent;
    border-color: white;
    margin: $spacer-sm 0;
    @include border-radius($input-border-radius);
  }

  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    background: $blue-96;
    border-radius: 0;
  }

  .react-datepicker__day:hover {
    background: white;
    color: $blue-47;
    border-color: $blue-47;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end {
    background: $blue-47;
    border-color: $blue-47;
    color: white;
  }

  .react-datepicker__day--range-start,
  .react-datepicker__day--selecting-range-start {
    border-radius: $input-border-radius 0 0 $input-border-radius;
  }
  .react-datepicker__day--range-end,
  .react-datepicker__day--selecting-range-end {
    border-radius: 0 $input-border-radius $input-border-radius 0;
  }

  .react-datepicker__day--today {
    border: 1px solid $grey-80;
  }

  .react-datepicker__day--disabled {
    opacity: 0.5;
  }

  .react-datepicker__day--outside-month {
    visibility: hidden;
    &::after {
      content: " ";
      height: 1em;
      display: inline-block;
    }
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__footer {
    clear: both;
    padding-top: $padding;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.react-datepicker.no-border {
  border: none;
  box-shadow: none;
  padding: 0;
  border-radius: 0;

  .react-datepicker__navigation {
    top: $navigation-button-offset;
  }

  .react-datepicker__navigation--previous {
    left: 0;

    &::after {
      left: 0;
      transform: scaleX(-1);
    }
  }

  .react-datepicker__navigation--next {
    right: 0;

    &::after {
      right: 0;
    }
  }
}
