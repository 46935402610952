.admin-modal .modal-dialog {
  max-width: 600px;
}

.admin-form {
  width: 400px;
  margin: auto;
}

.admin-form-header {
  font-size: 16px;
}

.square-button {
  width: 30px;
  display: inline-flex;
  justify-content: center;

  :first-child {
    margin: 0 !important;
  }
}

.confirmation-message {
  font-weight: 600;
  font-size: 18px;
}

.message-preview-text {
  font-size: 14px;
  line-height: 25.2px;
}

.message-preview-row {
  padding-left: 36px;
  padding-right: 36px;
}

@media (min-width: 992px) {
  .banner-preview-modal-small .modal-dialog {
    min-width: 80%;
  }
}

@media (min-width: 1300px) {
  .banner-preview-modal-small .modal-dialog {
    min-width: 50%;
  }
}

.banner-preview-modal-large .modal-dialog {
  min-width: 98%;
}

@media (min-width: 1500px) {
  .banner-preview-modal-large .modal-dialog {
    min-width: 1400px;
  }
}

.banner-preview-grey {
  background-color: rgb(243, 243, 243);
}

.card {
  margin-bottom: 20px;
}

.audit-log-message-cell {
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.general-setting-card {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-weight: bold;
}

.danger {
  color: var(--danger);
}

.success {
  color: var(--success);
}
