.form-control.no-validation-icon {
  &.is-invalid,
  &.is-valid {
    background-image: none;
  }
}

input[type="radio"],
input[type="checkbox"] {
  & + label {
    cursor: pointer;
  }
}

input:disabled,
select:disabled,
textarea:disabled,
.custom-control > .custom-control-input:disabled + .custom-control-label,
.custom-control > .custom-control-input:disabled + .custom-control-label::before {
  cursor: not-allowed !important;
}

.custom-select {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:disabled {
    background-color: $input-disabled-bg;
  }
}

input {
  & + .input-inner-element {
    position: absolute;
    right: 0;
    top: 0.5rem;
    transform: translate(-0.5rem, 0);
    cursor: pointer;

    display: block;
    width: auto;
    height: auto;
    & > * {
      display: block;
      width: 24/16 * 1rem;
      height: 24/16 * 1rem;
    }
  }
}

/* Possible webkit fix for forced internal autocomplete selection styles */
/*
input,
textarea,
select {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: $input-color;
    -webkit-box-shadow: 0 0 0px 1000px $input-bg inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}
*/
