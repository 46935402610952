.#{$prefix}layout {
  @include media-breakpoint-down(xs) {
    padding-left: 0;
    padding-right: 0;
  }

  &.layout-pre-auth {
    max-width: 824px;
    flex: 1;

    .invalid-feedback {
      display: block;
      border-radius: $border-radius;
      margin-top: 0.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.3125rem;
      padding-bottom: 0.3125rem;
      background-color: $red-100;
      text-align: center;
    }
  }
}
