.new_appendixes__address-text {
  color: #00264c;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.new-appendixes__table {
  tbody tr:first-child td {
    border-top: none;
  }

  tbody tr td:last-child {
    border: none;
  }
}

.wider-modal .modal-dialog {
  max-width: 620px;
}

.new-appendixes__table-container {
  max-height: 330px;
  overflow-x: hidden;
  overflow-y: auto;
}

.new-appendixes__scroll-gradient {
  position: relative;
}

.new-appendixes__scroll-gradient::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 98%;
  height: 40px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
}

.blue-scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #F3F3F4;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #004582;
    border-radius: 2px;
  }
}

.forced_password_warning {
  width: 90%;
  color: red;
  font-weight: bold;
}
