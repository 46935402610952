.#{$prefix}header {
  padding-top: 0;
  padding-bottom: 0;

  &__brand.navbar-brand {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  @include media-breakpoint-down(md) {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    &__brand.navbar-brand {
      img {
        max-width: 145px;
      }
    }
  }

  .navbar-nav {
    & > .nav-item {
      .dropdown-menu {
        border: 0;
        padding-top: 0;

        .show {
          padding-left: 0;
          padding-right: 0;
        }

        @include media-breakpoint-up(lg) {
          min-width: 400px;
          box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
          padding: 1.5rem 0;
        }
        .dropdown-item {
          font-weight: $font-weight-medium;
          padding: 0.75rem 1rem 0.25rem 2rem;
          color: $gray-700;

          @include media-breakpoint-up(lg) {
            &:first-child {
              padding-top: 0;
            }
          }
          &:last-child {
            padding-bottom: 0.25rem;
          }
          &.active,
          &:active,
          &:hover {
            background-color: $white;
          }
          &:hover {
            color: $indigo;
          }
          // .icon-check {
          //   display: none;
          // }
          .icon-check {
            display: block;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
          }

          @include media-breakpoint-up(lg) {
            color: $link-color;
            // .icon-check {
            //   display: block;
            //   position: absolute;
            //   right: 0;
            //   top: 50%;
            //   transform: translate(0, -50%);
            // }
          }
        }
        .dropdown-divider + .dropdown-item {
          margin-bottom: 0;
        }
      }
      .nav-link {
        display: flex;
        align-items: center;
        padding: 1rem 1.25rem;
        @include media-breakpoint-up(lg) {
          padding: 1.25rem 1rem;
        }
        color: $gray-700;
        font-weight: $font-weight-medium;
        white-space: nowrap;

        &:hover {
          color: $indigo;
        }

        &.active {
          @include media-breakpoint-up(lg) {
            background: linear-gradient(180deg, $blue-100 0%, rgba(239, 244, 255, 0) 100%);
          }
          color: $indigo;
          font-weight: bold;
        }
      }
    }
  }
  .navbar-toggler + .navbar-collapse > .navbar-nav {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .notification-placeholder + .navbar-collapse {
    @include media-breakpoint-down(md) {
      border-top: 1px solid $nav-divider-color;
      margin-bottom: 1rem;
      .dropdown-toggle {
        display: none;
        & + .dropdown-menu {
          display: block;
          padding-top: 0.5rem;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
  .notification-title {
    display: inline-flex;
    align-items: center;
    line-height: 1.5;
    color: $gray-700;
    font-weight: $font-weight-medium;
    font-size: 1rem;
    white-space: nowrap;
    &:hover {
      color: $indigo;
    }
  }
  .dropdown-notification {
    & > .dropdown-menu {
      border: 0;
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
      padding: 1.5rem 2rem;
      min-width: 290px;
      transform: translate(50px, 0);
      @include media-breakpoint-up(lg) {
        min-width: 420px;
        transform: translate(0, 11px);
      }
    }
  }
}
