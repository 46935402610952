.#{$prefix}messages-widget {
  &__container {
    padding-top: 1.5rem;
  }
  &__row {
    padding: 1rem 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    .#{$prefix}messages-widget__datetime {
      font-size: $font-size-sm;
      color: $gray-600;
      margin-bottom: 0.25rem;
    }
    .#{$prefix}messages-widget__message {
      font-size: $font-size-sm;
      line-height: $line-height-sm;
      color: $gray-700;
    }
    &--unread {
      .#{$prefix}messages-widget__message {
        color: $blue;
        font-weight: $font-weight-medium;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid $gray-200;
    }
  }
  &__indicator {
    position: absolute;
    top: 0.5em;
    left: -1.125em;
  }
}
