.dropdown__accounts-header {
  font-weight: 600;
}

.dropdown__main-account {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.dropdown__main-account-wrapper {
  padding-right: 1rem !important;
}

.dropdown__main-account-divider {
  width: 100%;
  border-bottom: 1px solid #0070cb;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.dropdown__user-name {
  color: #0070cb;
  font-weight: bold;

  &:hover {
    color: #00264c;
  }
}

.dropdown__connected-accounts-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.dropdown__divider {
  border-top: 1px solid #dfe0e2;
  width: 100%;
  margin-top: 1.2rem;
  margin-bottom: 1rem;
}

.connected-partners__row {
  font-weight: 400;
  height: 2.7rem;
  border-bottom: 1px solid #dfe0e2;

  &.last-row {
    border-bottom: none;
  }
}

.connected-partners__container {
  max-height: 270px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: 32px;
  margin-top: 0.5rem;
}

.floating.badge {
  // will need media query for burger menu positioning
  position: absolute;
  top: 0.9rem;
  left: 1.5rem;

  @media (max-width: 992px) {
    top: 0.5rem;
    left: 1.7rem;
  }
}

.floating-message.badge {
  position: absolute;
  top: 0.5rem;
  left: 1.7rem;
}

// moved from finance/style.scss as it affects all number inputs globally
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.loginRoot {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
