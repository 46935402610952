.description {
  color: #8a8e98 !important;
  font-size: 0.875rem;
}

.downloadCard {
  background: #F3F3F4;
  border: none;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  padding-left: 0.85rem;
  padding-right: 0.85rem;
  font-size: 0.875rem;
}

.bulkResult {
  position: fixed;
  left: 0;
  top: 76px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  background: white;
  z-index: 1;

  .action {
    margin: 0 !important;
  }
}
