.small-banner__background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.small-banner_text {
  color: white;
  font-size: 18px;
  line-height: 24.5px;
  width: 60%;
}
